.mobilePopup {
  background-color: #6c37f4;
  height: 70px;
  display: flex;
  width: auto;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  gap: 14px;
  border-radius: 0px;
  padding: 0 24px 0 24px;
  width: 100vw;
  border-top: 2px solid #ebff5e;
}

.desktopPopup {
  background-color: #6c37f4;
  height: 54px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  gap: 14px;
  padding: 0 24px 0 24px;
  border: 1px solid #ebff5e;
  border-radius: 35px;
}
